import React, { useEffect, useState, useContext } from "react";
import { Box, Icon, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { BarChart, axisClasses } from "@mui/x-charts";
import { getRoles, user_name } from "../context/RequireAuth";
import AuthContext from "../context/authContext";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

const chartSetting = {
  width: 540,
  height: 440,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-20px, 0)",
      fill: "#fff",
    },
    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
      stroke: "#fff",
      fill: "#fff",
      strokeWidth: 1,
    },
    // leftAxis Line Styles
    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
      stroke: "#fff",
      fill: "#fff",
      strokeWidth: 1,
    },
    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
      strokeWidth: "1",
      fill: "#fff",
    },

    // change bottom label styles
    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
      strokeWidth: "1",
      fill: "#fff",
    },
  },
};
const dataset = [
  {
    cable: 21000,
    internet: 13000,
    month: "Jan",
  },
  {
    cable: 31000,
    internet: 19000,
    month: "Feb",
  },
  {
    cable: 28000,
    internet: 15000,
    month: "Mar",
  },
  {
    cable: 34000,
    internet: 21000,
    month: "Apr",
  },
  {
    cable: 11000,
    internet: 4000,
    month: "May",
  },
  {
    cable: 14000,
    internet: 7000,
    month: "June",
  },
];

const valueFormatter = (value) => `${value}Rs`;

const statisticbody = (statName, statValue) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography
        sx={{ color: "#fff", fontSize: "16px", whiteSpace: "nowrap" }}
      >
        {statName}
      </Typography>
      <Typography
        sx={{
          color: "#fff",
          fontSize: "16px",
        }}
      >
        {statValue}
      </Typography>
    </Box>
  );
};

const dashcards = (icon, content, value) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "130px",
        // backgroundColor: "rgba(213, 213, 224, 0.5)",
        backgroundImage:
          "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
        borderRadius: "20px",
        border: "2px solid #FDBB2C",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      {/* Chart Icon */}
      <Box>
        <Icon
          sx={{
            color: "#FDBB2C",
            // backgroundColor: "#FDBB2C",

            width: "80px",
            height: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
          }}
        >
          {icon}
        </Icon>
      </Box>
      {/* chart text */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            color: "#fff",
            fontWeight: "bolder",
          }}
        >
          {content}
        </Typography>
        <Typography
          sx={{
            fontSize: "35px",
            color: "#FDBB2C",
            fontWeight: "bolder",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const Dashboard = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const [role, setRole] = useState("");
  const [totalTodayPaymentAmount, setTotalTodayPaymentAmount] = useState(0);
  const [totalUserPaymentAmount, setTotalUserPaymentAmount] = useState(0);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [totalBalanceAmount, setTotalBalanceAmount] = useState(0);
  const [totalMonthAmount, setTotalMonthAmount] = useState(0);
  const [todayComplaints, setTodayComplaints] = useState(0);
  const [monthComplaints, setMonthComplaints] = useState(0);
  const [todayCustomer, setTodayCustomer] = useState(0);
  const [monthCustomer, setMonthCustomer] = useState(0);
  const [datasets, setDatasets] = useState([]);

  // User Data Fetch
  useEffect(() => {
    setRefresh(true);
  }, []);
  useEffect(() => {
    if (userData) {
      const clientData = userData;
      const currentuser = user_name();

      const allusers = clientData?.users?.filter(
        (user) => user.username.toLowerCase() === currentuser.toLowerCase()
      );

      // client total amount check
      let today = new Date().toISOString();
      const todayDate = today.split("T")[0];
      const filtrdcust = clientData?.customers?.filter(
        (ite) => ite.customer_status === "Active"
      );
      const totalAmountSoFar = clientData?.customers
        ?.flatMap(
          (customer) =>
            customer?.payments?.map((payment) => {
              const amount = parseFloat(payment?.amount);
              return isNaN(amount) ? 0 : amount;
            }) || []
        )
        .reduce((total, amount) => total + amount, 0);

      setTotalPaymentAmount(totalAmountSoFar);

      const totalBalancesofar = clientData?.customers?.reduce(
        (total, customer) => {
          const balance = customer.cable_balance || 0; // Use 0 if cable_balance is undefined
          return total + balance;
        },
        0
      );

      setTotalBalanceAmount(totalBalancesofar);

      const compTodayDate = new Date().toISOString().split("T")[0];

      const openComplaintsToday = userData?.complaints?.filter(
        (complaint) =>
          complaint.status === "Open" &&
          complaint.date.split("T")[0] === compTodayDate
      ).length;
      setTodayComplaints(openComplaintsToday);
      const openComplaintsMonth = userData?.complaints?.filter(
        (complaint) =>
          complaint.status === "Open" &&
          complaint.date.split("-")[1] === compTodayDate.split("-")[1]
      ).length;
      setMonthComplaints(openComplaintsMonth);

      const currentDate = new Date().toISOString().split("T")[0].slice(0, -3);

      const totalAmountThisMonth = userData?.customers
        ?.flatMap((customer) =>
          customer?.payments?.filter(
            (payment) => payment?.pay_date?.slice(0, -3) === currentDate
          )
        )
        .reduce((sum, payment) => sum + (payment?.amount || 0), 0);

      setTotalMonthAmount(totalAmountThisMonth);
      const todayCustomers = clientData?.customers?.filter(
        (customer) => customer?.registerdate?.split("T")[0] === todayDate
      ).length;

      setTodayCustomer(todayCustomers);

      const currentMonth = new Date().toISOString().slice(0, 7);

      const monthCustomers = clientData?.customers?.filter(
        (customer) => customer?.registerdate?.slice(0, 7) === currentMonth
      ).length;

      setMonthCustomer(monthCustomers);

      let totalAmount = 0;

      const payments = filtrdcust?.forEach((pays) =>
        pays.payments.forEach((custpaid) => {
          if (custpaid.pay_date === todayDate) {
            totalAmount += custpaid.amount;
          }
        })
      );

      setTotalTodayPaymentAmount(totalAmount);

      // If client found, calculate total payment amount
      if (allusers <= 0) {
        let totalUserAmount = 0;
        const filtrdcust = clientData?.customers?.filter(
          (ite) => ite.customer_status === "Active"
        );
        const paymentsss = filtrdcust.forEach((pays) =>
          pays.payments.forEach((custpaid) => {
            if (
              custpaid.pay_date === todayDate &&
              custpaid.collected_by.toLowerCase() ===
                clientData.name.toLowerCase()
            ) {
              totalUserAmount += custpaid.amount;
            }
          })
        );

        setTotalUserPaymentAmount(totalUserAmount);
      } else {
        let totalUserAmount = 0;
        const filtrdcust = clientData?.customers?.filter(
          (ite) => ite.customer_status === "Active"
        );
        const paymentsssss = filtrdcust?.forEach((pays) =>
          pays.payments.forEach((custpaid) => {
            if (
              custpaid.pay_date === todayDate &&
              custpaid.collected_by.toLowerCase() ===
                allusers[0].name.toLowerCase()
            ) {
              totalUserAmount += custpaid.amount;
            }
          })
        );
        setTotalUserPaymentAmount(totalUserAmount);
      }
    }
  }, [userData]);
  useEffect(() => {
    setRole(getRoles());
  }, []);
  if (!userData) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        minHeight: "100vh",
        padding: { xs: "5px 10px", lg: "15px 40px" },
        flexWrap: "wrap",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      {/* Top Section Charts */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: { xs: "center", lg: "space-between" },
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {/* LeftSide Charts */}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            flex: { xs: "none", sm: 1 },
            width: "100%",
          }}
        >
          {/* chart 1 */}
          {role === "client" && (
            <Box
              sx={{
                width: "100%",
                height: "130px",
                // backgroundColor: "rgba(213, 213, 224, 0.5)",
                backgroundImage:
                  "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
                borderRadius: "20px",
                border: "2px solid #FDBB2C",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {/* Chart Icon */}
              <Box>
                <Icon
                  sx={{
                    color: "#FDBB2C",
                    // backgroundColor: "#FDBB2C",
                    width: "100%",
                    height: "max-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                  }}
                >
                  <GroupsIcon sx={{ fontSize: "50px", height: "50px" }} />
                </Icon>
              </Box>
              {/* chart text */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{ fontSize: "20px", color: "#fff", fontWeight: "bolder" }}
                >
                  Customers
                </Typography>

                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#30D479", fontSize: "16px" }}>
                      Active
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: "#FDBB2C",
                        fontWeight: "bolder",
                      }}
                    >
                      {
                        userData?.customers?.filter(
                          (cust) => cust.customer_status === "Active"
                        ).length
                      }
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "red", fontSize: "16px" }}>
                      InActive
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FDBB2C",
                        fontWeight: "bolder",
                        fontSize: "24px",
                      }}
                    >
                      {userData?.customers?.filter(
                        (cust) =>
                          cust.customer_status !== "trash" &&
                          cust.customer_status !== "Erased"
                      ).length -
                        userData?.customers?.filter(
                          (cust) => cust.customer_status === "Active"
                        ).length}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* chart 2 */}
          <Box
            sx={{
              width: "100%",
              height: "130px",
              // backgroundColor: "rgba(213, 213, 224, 0.5)",
              backgroundImage:
                "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
              borderRadius: "20px",
              border: "2px solid #FDBB2C",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {/* Chart Icon */}
            <Box>
              <Icon
                sx={{
                  color: "#FDBB2C",
                  // backgroundColor: "#FDBB2C",

                  width: "100%",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <CurrencyRupeeIcon sx={{ fontSize: "50px", height: "50px" }} />
              </Icon>
            </Box>
            {/* chart text */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",

                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", color: "#fff", fontWeight: "bolder" }}
              >
                Payments Today
              </Typography>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {role === "client" && (
                    <Typography sx={{ color: "#30D479", fontSize: "16px" }}>
                      Self
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontSize: "24px",
                      color: "#FDBB2C",
                      fontWeight: "bolder",
                    }}
                  >
                    {totalUserPaymentAmount}
                  </Typography>
                </Box>
                {role === "client" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "red", fontSize: "16px" }}>
                      All
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FDBB2C",
                        fontWeight: "bolder",
                        fontSize: "24px",
                      }}
                    >
                      {totalTodayPaymentAmount}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {/* chart 3 */}
          {role === "client" &&
            dashcards(
              <SupportAgentIcon sx={{ width: "50px", height: "50px" }} />,
              "Total Users",
              userData?.users?.length
            )}
        </Box>
        {/* Center Charts */}
        {role === "client" && (
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              gap: "20px",

              flexDirection: "column",
              flex: { xs: "none", sm: 2 },
            }}
          >
            <Box
              sx={{
                // width: { lg: "600px", xs: "260px" },
                height: { lg: "440px", xs: "250px" },
                // height: "88%",
                // backgroundColor: "rgba(213, 213, 224, 0.5)",
                backgroundImage:
                  "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
                borderRadius: "20px",
                border: "2px solid #FDBB2C",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  fontSize: "20px",
                  color: "#fff",
                  marginTop: "30px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#fdbb2c",
                  }}
                ></Box>
                <Typography>Cable</Typography>
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#30D479",
                  }}
                ></Box>
                <Typography>Internet</Typography>
              </Box>
              <BarChart
                dataset={dataset}
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "month",
                    categoryGapRatio: 0.5,
                    barGapRatio: 0.05,
                  },
                ]}
                series={[
                  {
                    dataKey: "cable",
                    valueFormatter,
                    color: "#FDBB2C",
                  },
                  {
                    dataKey: "internet",
                    valueFormatter,
                    color: "#30D479",
                  },
                ]}
                {...chartSetting}
              />
            </Box>
          </Box>
        )}
        {/* RightSide Charts */}
        {role === "client" && (
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              flex: { xs: "none", sm: 1 },
              width: "100%",
            }}
          >
            {/* chart 4 */}
            {dashcards(
              <GroupsIcon sx={{ width: "50px", height: "50px" }} />,
              "Total Customers",
              userData?.customers?.filter(
                (cust) =>
                  cust.customer_status !== "trash" &&
                  cust.customer_status !== "Erased"
              ).length
            )}

            {/* chart 5 */}
            {role === "client" && (
              <>
                {dashcards(
                  <CurrencyRupeeIcon sx={{ width: "50px", height: "50px" }} />,
                  "Monthly Payments",
                  totalMonthAmount ? totalMonthAmount : 0
                )}
              </>
            )}
            {/* chart 6 */}
            {role === "client" && (
              <>
                {dashcards(
                  <QuestionAnswerIcon sx={{ width: "50px", height: "50px" }} />,
                  "SMS Balance",
                  userData?.total_msg_remaining || 0
                )}
              </>
            )}
          </Box>
        )}
      </Box>
      {/* Bottom Charts */}
      {/* Statistics */}
      {role === "client" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {/* Customers */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              backgroundImage:
                "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
              border: "2px solid #fDbb2c",
              width: { xs: "100%", md: "20%" },
              // width: {xs:"80vw",sm:"max-content"},
              borderRadius: "15px",
              flexDirection: "column",
              height: "max-content",
              flex: 1,
            }}
          >
            <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
              Customer Statistics
            </Typography>
            {/* Total Customers */}
            {statisticbody("Total Customers", userData?.customers?.length)}

            {/* Active Customers */}
            {statisticbody(
              "Active Customers",
              userData?.customers?.filter(
                (cust) => cust.customer_status === "Active"
              ).length
            )}

            {/* Registered Today */}
            {statisticbody("Registered Today", todayCustomer)}

            {/* Registered this Month */}
            {statisticbody("Registered this Month", monthCustomer)}
          </Box>
          {/* Payments */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              border: "2px solid #fDbb2c",
              backgroundImage:
                "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
              width: { xs: "100%", md: "20%" },
              borderRadius: "15px",
              flexDirection: "column",
              height: "max-content",
              flex: 1,
            }}
          >
            <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
              Payment Statistics
            </Typography>
            {/* Total Payments */}
            {statisticbody(" Total Payments", totalPaymentAmount)}

            {/* Total Balance */}
            {statisticbody("Total Balance", totalBalanceAmount)}

            {/* Payments Today */}
            {statisticbody("Payments Today", totalTodayPaymentAmount)}

            {/* Payments this Month */}
            {statisticbody("Payments this Month", totalMonthAmount)}
          </Box>
          {/* Expenses */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              border: "2px solid #fDbb2c",
              backgroundImage:
                "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
              width: { xs: "100%", md: "20%" },
              borderRadius: "15px",
              flexDirection: "column",
              height: "max-content",
              flex: 1,
            }}
          >
            <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
              Expense Statistics
            </Typography>
            {/* Total Expenses */}
            {statisticbody("Total Expenses", 0)}

            {/* Total Purchase */}
            {statisticbody("Total Purchase", 0)}

            {/* Payments this month */}
            {statisticbody("Payments this Month", 0)}

            {/* Expenses this Month */}
            {statisticbody("Expenses this Month", 0)}
          </Box>
          {/* Complaints */}
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              border: "2px solid #fDbb2c",
              backgroundImage:
                "linear-gradient(to bottom right, #0f0c29, #302b63,#24243e)",
              width: { xs: "100%", md: "20%" },
              borderRadius: "15px",
              flexDirection: "column",
              height: "max-content",
              flex: 1,
            }}
          >
            <Typography sx={{ color: "#fdbb2c", textAlign: "center" }}>
              Complaint Statistics
            </Typography>
            {/* Total Complaints */}
            {statisticbody("Total Complaints", userData?.complaints?.length)}

            {/* Total Pending */}
            {statisticbody(
              "Total Pending Complaints",
              userData?.complaints?.filter(
                (complaint) => complaint.status === "Open"
              ).length
            )}

            {/* Complaints Today */}
            {statisticbody("Complaints Today", todayComplaints)}

            {/* Complaints this Month */}
            {statisticbody("Complaints this Month", monthComplaints)}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
