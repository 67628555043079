import {
  Box,
  Button,
  Paper,
  InputBase,
  Select,
  MenuItem,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  IconButton,
  Modal,
  TextField,
  CircularProgress,
  InputLabel,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "../../api/axios";
import toast from "react-hot-toast";
import ViewCustomerModal from "../../components/modals/viewcustomermodal";
import TablePagination from "@mui/material/TablePagination";
import AuthContext from "../../context/authContext";
import RestoreIcon from "@mui/icons-material/Restore";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
const columns = [
  "S.No",
  "Name",
  "CAF No",
  "Area",
  "STB No",
  "Mobile",
  "Plan",
  "Balance",
  "Last Paid",
  "Status",
  "Payments",
  "Actions",
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  color: "#fff",
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  p: 3,
};
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "280px",
  height: "40px",
  backgroundColor: "#fbbd2c",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#fff",
      backgroundImage: "linear-gradient(to bottom right, #302b63, #24243e)",
    },
  },
};

const TrashCustomers = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const tableRef = useRef(null);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [area, setArea] = useState("All");
  const [payType, setPayType] = useState("All");
  const [openCustomerViewModal, setOpenCustomerViewModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedid, setSelectedid] = useState("");
  const [openRestore, setOpenRestore] = useState(false);
  const [custViewData, setCustViewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchStbNo, setSearchStbNo] = useState("");
  const [CafNo, setCafNo] = useState("");
  const handleCloseRestore = () => {
    setOpenRestore(false);
    setSearchStbNo("");
    setCafNo("");
  };

  const handleOpenCustomerView = () => setOpenCustomerViewModal(true);
  const handleCloseCustomerView = () => {
    setOpenCustomerViewModal(false);
    setArea("All");
    setPayType("All");
    setRefresh(true);
  };

  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      // If header checkbox is checked, select all checkboxes
      const selectedIds = filteredData.map((item) => item.caf_no);
      setSelectedCheckboxes(selectedIds);
    } else {
      // If header checkbox is unchecked, deselect all checkboxes
      setSelectedCheckboxes([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;
    if (checked) {
      // If checkbox is checked, add to selectedCheckboxes
      setSelectedCheckboxes((prevSelected) => [...prevSelected, id]);
    } else {
      // If checkbox is unchecked, remove from selectedCheckboxes
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const handleFilters = () => {
    // Apply area filter
    let filteredDataByArea = [];
    if (area === "All") {
      filteredDataByArea = userData?.customers?.filter(
        (filt) => filt.customer_status === "trash"
      );
    } else {
      const trashcust = userData?.customers?.filter(
        (filt) => filt.customer_status === "trash"
      );
      filteredDataByArea = trashcust.filter((customer) =>
        area.includes(customer.area)
      );
    }
    // Apply payment status filter
    let filteredDataByPaymentStatus = [];
    if (payType !== "All") {
      filteredDataByPaymentStatus = filteredDataByArea.filter(
        (customer) => customer.payment_status === payType
      );
    } else {
      filteredDataByPaymentStatus = filteredDataByArea;
    }

    let filterSearch = [];
    if (!search) {
      filterSearch = filteredDataByPaymentStatus;
    } else {
      const searchTerm = search.toLowerCase();
      filterSearch = filteredDataByPaymentStatus.filter((customer) =>
        Object.values(customer).some((value) => {
          if (value === null || value === undefined) return false;
          return value.toString().toLowerCase().includes(searchTerm);
        })
      );
    }

    setFilteredData(filterSearch);
  };
  useEffect(() => {
    handleFilters();
  }, [area, search, payType]);
  // User Data Fetch

  useEffect(() => {
    if (userData.customers) {
      const filters = userData?.customers.filter(
        (cust) => cust.customer_status === "trash"
      );
      setFilteredData(filters);
    }
    handleFilters();
  }, [userData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRestoreCustomers = async () => {
    if (!CafNo || !searchStbNo) {
      toast.error("Please fill all the fields");
    }
    const currentCafCheck = userData?.customers?.filter(
      (cust) =>
        cust.caf_no.toLowerCase() === CafNo.toLowerCase() &&
        cust.customer_status !== "trash"
    );
    if (currentCafCheck.length !== 0) {
      toast.error("CafNo Already Exists");
    }
    const checkSTB = userData?.customers?.filter(
      (cust) =>
        cust.stb_no.toLowerCase() === searchStbNo.toLowerCase() &&
        cust.customer_status !== "trash"
    );
    if (checkSTB.length !== 0) {
      toast.error("STB No Already Exists");
    }
    try {
      const res = await axios.post(
        "/restorecustomer",
        { selectedid, CafNo, searchStbNo },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
          },
        }
      );
      // Pending work is on connecting the restore customers from frontend to backend.
      if (res.status === 200) {
        toast.success("Customer Restored");
        setRefresh(true);
      } else {
        toast.error("Please try again");
      }
    } catch (error) {
      console.log("Error Restoring Customer", error);
    }
  };
  const handleDeleteCustomer = async () => {
    try {
      const res = await axios.post(
        "/eraseCustomer",
        { selectedCheckboxes: selectedCheckboxes },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
          },
        }
      );
      if (res.status === 200) {
        toast.success("Customer Deleted");
        setRefresh(true);
        handleFilters();
      } else {
        toast.error("Please try again");
      }
    } catch (error) {
      console.log("Error deleting Customer", error);
    }
  };
  const handleviewCustomer = (id) => {
    const filters = userData?.customers?.filter((item) => item._id === id);
    setCustViewData(filters);
    handleOpenCustomerView();
  };
  const handleRestoreCustomer = async (id) => {
    setSelectedid(id);
    setOpenRestore(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        color: "#fff",
        margin: "20px",
        minHeight: "100vh",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {/* Top Box */}
      <Box
        sx={{
          display: "flex",
          width: "85vw",
          flexWrap: "wrap-reverse",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {/* Filter Buttons */}
        <Box
          sx={{
            display: "flex",
            width: 250,
            justifyContent: "space-between",
          }}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            defaultValue={"All"}
            value={area}
            onChange={(e) => setArea(e.target.value)}
            renderValue={(selected) => selected}
            MenuProps={MenuProps}
            sx={{
              backgroundColor: "#fbbd2c",
              width: "100px",
              height: "50px",
              borderColor: "#fbbd2c",
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {userData?.areas?.map((item) => (
              <MenuItem key={item.area_name} value={item.area_name}>
                {item.area_name}
              </MenuItem>
            ))}
          </Select>

          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={payType}
            onChange={(e) => setPayType(e.target.value)}
            renderValue={(selected) => selected}
            MenuProps={MenuProps}
            sx={{
              backgroundColor: "#fbbd2c",
              width: "100px",
              height: "50px",
              borderColor: "#fbbd2c",
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Paid"}>Paid</MenuItem>
            <MenuItem value={"Unpaid"}>UnPaid</MenuItem>
          </Select>
        </Box>
        {/* Search Button */}
        <Box>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 260,
              height: 45,
              backgroundColor: "#fbbd2c",
              borderRadius: "30px",
            }}
          >
            <InputBase
              sx={{
                ml: 2,
                flex: 1,
                color: "#000",
              }}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ "aria-label": "Search" }}
            />
          </Paper>
        </Box>
        {/* {Restore / Delete Buttons} */}
        <Box
          sx={{
            display: "flex",
            width: 250,
            justifyContent: "space-between",
          }}
        >
          {/* {selectedCheckboxes.length < 1 && (
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                width: "100px",
                height: "40px",
                borderColor: "#fbbd2c",
                color: "#000",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
              onClick={handleRestoreCustomers}
            >
              Restore
            </Button>
          )} */}

          <Button
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              backgroundColor: "#fbbd2c",
              width: "100px",
              height: "40px",
              borderColor: "#fbbd2c",
              color: "#000",
              "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
            }}
            onClick={handleDeleteCustomer}
          >
            Delete
          </Button>
        </Box>
      </Box>
      {/* Table */}
      <Box sx={{ overflow: "scroll" }}>
        <Table sx={{ border: "none" }} ref={tableRef}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
              <TableCell>
                <input type="checkbox" onChange={handleHeaderCheckboxChange} />
              </TableCell>

              {columns.map((item) => (
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  key={item}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <input
                      type="checkbox"
                      onChange={(event) =>
                        handleCheckboxChange(event, item.caf_no)
                      }
                      checked={selectedCheckboxes.includes(item.caf_no)}
                    />
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>{index + 1}</TableCell>
                  <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                    {item.customer_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.caf_no.toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                    {item.area}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                    {item.stb_no}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                    {item.mobile}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.cable_plan}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: item.cable_balance >= 0 ? "#fff" : "red",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.cable_balance}
                  </TableCell>
                  <TableCell sx={{ color: "#fff", whiteSpace: "nowrap" }}>
                    {(item.payments.length > 0 &&
                      item.payments.slice().reverse()[0].pay_date) ||
                      "New"}
                  </TableCell>
                  <TableCell
                    sx={{
                      color:
                        item.customer_status === "Active" ? "#30D479" : "red",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.customer_status}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.payment_status}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      display: "flex",
                      padding: "10px",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "#fbbd2c",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#000",
                        },
                      }}
                      onClick={() => handleviewCustomer(item._id)}
                    >
                      <Icon>
                        <VisibilityIcon />
                      </Icon>
                    </IconButton>
                    <IconButton
                      sx={{
                        color: "#fbbd2c",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#000",
                        },
                      }}
                      onClick={() => handleRestoreCustomer(item._id)}
                    >
                      <Icon>
                        <RestoreIcon />
                      </Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 25, 100, 500, 1000]}
          component="div"
          count={filteredData ? filteredData.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            color: "#fbbd2c",
            "& .MuiSvgIcon-root": {
              // Targeting all SVG icons
              fill: "#fbbd2c", // Arrow color
            },
          }}
        />
      </Box>
      <ViewCustomerModal
        openCustomerViewModal={openCustomerViewModal}
        handleCloseCustomerView={handleCloseCustomerView}
        custViewData={custViewData}
      />
      <Modal
        open={openRestore}
        onClose={handleCloseRestore}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color: "#fff",
                alignItems: "start",
                margin: "10px 10px",
              }}
            >
              <InputLabel sx={{ color: "#fff" }}>CAF No</InputLabel>
              <StyledInputBase
                placeholder={"CAF No"}
                type="text"
                onChange={(e) => {
                  setCafNo(e.target.value);
                }}
                value={CafNo}
              />
            </Box>
            <Stack>
              <InputLabel sx={{ color: "#fff" }}>STB No</InputLabel>
              <Autocomplete
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "280px",
                  backgroundColor: "#fbbd2c",
                  borderRadius: "30px",
                }}
                onChange={(e, value) => {
                  // "value" will contain the selected or typed value when freeSolo is true
                  setSearchStbNo(value);
                }}
                onInputChange={(e, value) => {
                  // Handles input changes when typing manually
                  setSearchStbNo(value);
                }}
                value={searchStbNo}
                freeSolo
                defaultValue={null}
                disableClearable
                options={
                  (userData &&
                    userData.stocks &&
                    userData.stocks
                      .filter((item) => item.status !== "used")
                      .map((option) => option.stb_no)) ||
                  []
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ color: "#000" }}
                    {...params}
                    label="Search"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: "#000" },
                    }}
                    onFocus={(e) =>
                      e.target.setAttribute("style", "color: #000")
                    }
                  />
                )}
              />

              {/* <Autocomplete
              // PaperComponent={PaperComponent}
              sx={{
                display: "flex",
                alignItems: "center",
                width: { xs: "100%", sm: 400 },
                backgroundColor: "#302b63",
                borderRadius: "30px",
              }}
              onChange={(e, value) => {
                setSearchStbNo(value);
              }}
              value={searchStbNo}
              freeSolo
              defaultValue={null}
              disableClearable
              options={
                (userData &&
                  userData.stocks &&
                  userData.stocks
                    .filter((item) => item.status !== "used")
                    .map(
                      (option) =>
                        option.caf_no.toUpperCase() +
                        " " +
                        option.mobile +
                        " " +
                        option.customer_name
                    )) ||
                []
              }
              filterOptions={(options, { inputValue }) =>
                options
                  .filter((option) =>
                    option.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .slice(0, 7)
              }
              renderInput={(params) => (
                <TextField
                  sx={{ color: "#fff" }}
                  {...params}
                  label="Search"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: "silver" }, // Set label color to white
                  }}
                  onFocus={(e) => e.target.setAttribute("style", "color: #fff")}
                />
              )}
            /> */}
            </Stack>
            <Button
              sx={{
                color: "#000",
                width: "max-content",
                backgroundColor: "#fbbd2c",
                // width: { xs: "max-content", sm: "180px" },
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "20px",
                padding: "10px 15px",
                "&:hover": {
                  backgroundColor: "#fbbd2c",
                },
              }}
              onClick={handleRestoreCustomers}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TrashCustomers;
