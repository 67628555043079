import {
  Box,
  InputBase,
  Modal,
  Select,
  styled,
  MenuItem,
  InputLabel,
  Typography,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext";
import toast from "react-hot-toast";
import axios from "../../api/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 500,
  color: "#fff",
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  height: "35px",
  backgroundColor: "#fff",
  borderRadius: "20px",
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  height: "35px",
  backgroundColor: "#fff",
  padding: "0px 15px",
  borderRadius: "20px",
}));

const StockModal = ({ openStock, closeOpenStock, editMode, editData }) => {
  const { userData } = useContext(AuthContext);

  // State for form fields
  const [stb, setStb] = useState("");
  const [stbType, setStbType] = useState("sd");
  const [stbProvider, setStbProvider] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [purchaseAmount, setPurchaseAmount] = useState("");

  const handleAddSTB = async () => {
    const data = {
      stb,
      stbType,
      stbProvider,
      purchaseDate,
      purchaseAmount,
    };

    const response = await axios.post(
      "/addstb",
      { data },
      {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          lcono: localStorage.getItem("lcono"),
          role: userData.role,
        },
      }
    );

    if (response.data.status === 200) {
      closeOpenStock();
      toast.success("STB added successfully");
    }
  };

  const handleEditSTB = async () => {
    const data = {
      stb,
      stbType,
      stbProvider,
      purchaseDate,
      purchaseAmount,
    };

    try {
      const response = await axios.put(
        `/editstb/${editData._id}`, // The PUT request to update the STB by ID
        { data }, // The updated STB data
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
            role: userData.role,
          },
        }
      );

      if (response.data.status === 200) {
        closeOpenStock(); // Close the modal after success
        toast.success("STB updated successfully"); // Show success toast
      }
    } catch (error) {
      console.error("Failed to update STB:", error);
      toast.error("Failed to update STB");
    }
  };

  useEffect(() => {
    if (editMode && editData) {
      setStb(editData.stb_no || "");
      setStbType(editData.stb_type || "sd");
      setStbProvider(editData.stb_provider || "");

      // Ensure the date is in the correct format for the date input field
      const formattedDate = editData?.purchase_date
        ? new Date(editData.purchase_date).toISOString().split("T")[0]
        : new Date().toISOString().split("T")[0];

      setPurchaseDate(formattedDate);

      setPurchaseAmount(editData.purchase_amount || "");
    }
  }, [editMode, editData]);

  return (
    <Modal
      open={openStock}
      onClose={closeOpenStock}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ color: "#fbbd2c", textAlign: "center", fontWeight: "bold" }}
        >
          STB Details
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* STB Number */}
          <InputLabel sx={{ color: "#fff" }}>STB Number</InputLabel>
          <StyledInputBase
            value={stb}
            onChange={(e) => setStb(e.target.value)}
            placeholder="STB Number"
            type="text"
          />

          {/* STB Type */}
          <InputLabel sx={{ color: "#fff" }}>STB Type</InputLabel>
          <StyledSelect
            value={stbType}
            onChange={(e) => setStbType(e.target.value)}
          >
            <MenuItem value={"sd"}>SD</MenuItem>
            <MenuItem value={"hd"}>HD</MenuItem>
            <MenuItem value={"hybrid"}>Hybrid</MenuItem>
          </StyledSelect>

          {/* STB Provider */}
          <InputLabel sx={{ color: "#fff" }}>STB Provider</InputLabel>
          <StyledSelect
            value={stbProvider}
            defaultValue={userData?.mso_isp?.[0]?.provider_name}
            onChange={(e) => setStbProvider(e.target.value)}
          >
            {userData?.mso_isp?.map((item) => (
              <MenuItem value={item.provider_name} key={item.provider_name}>
                {item.provider_name}
              </MenuItem>
            ))}
          </StyledSelect>

          {/* Purchase Date */}
          <InputLabel sx={{ color: "#fff" }}>Purchase Date</InputLabel>
          <StyledInputBase
            value={purchaseDate}
            onChange={(e) => setPurchaseDate(e.target.value)}
            type="date"
          />

          {/* Purchase Amount */}
          <InputLabel sx={{ color: "#fff" }}>Purchase Amount</InputLabel>
          <StyledInputBase
            value={purchaseAmount}
            onChange={(e) => setPurchaseAmount(e.target.value)}
            placeholder="Amount"
            type="number"
          />
        </Box>

        {editMode ? (
          <Button
            title="Add Stock"
            sx={{
              color: "#000",
              width: "100%",
              marginTop: "20px",
              backgroundColor: "#fbbd2c",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px 15px",
              "&:hover": { backgroundColor: "#fbbd2c" },
            }}
            onClick={handleEditSTB}
          >
            Update
          </Button>
        ) : (
          <Button
            title="Add Stock"
            sx={{
              color: "#000",
              width: "100%",
              marginTop: "20px",
              backgroundColor: "#fbbd2c",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px 15px",
              "&:hover": { backgroundColor: "#fbbd2c" },
            }}
            onClick={handleAddSTB}
          >
            Add
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default StockModal;
