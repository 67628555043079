import {
  Box,
  styled,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "../api/axios";
import toast from "react-hot-toast";
import AuthContext from "../context/authContext";

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "260px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));

const Messages = () => {
  const { userData, setRefresh } = useContext(AuthContext);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [msgtemp, setMsgTemp] = useState([]);
  const inputRefProvider = useRef(null);
  const inputRefMessages = useRef(null);
  const inputRefArea = useRef(null);
  const inputRefCustomerType = useRef(null);

  const handleGetCustomers = async () => {
    const prov = inputRefProvider.current.value;
    const area = inputRefArea.current.value;
    const status = inputRefCustomerType.current.value;

    // Apply provider filter
    let filteredDataByProvider = [];
    const filtered = userData?.customers?.filter(
      (item) =>
        item.customer_status !== "trash" && item.customer_status !== "Erased"
    );

    if (prov !== "All") {
      filteredDataByProvider = filtered.filter(
        (provider) => provider.stb_provider === prov
      );
    } else {
      filteredDataByProvider = filtered;
    }

    // Apply area filter
    let filteredDataByArea = [];
    if (area === "All") {
      filteredDataByArea = filteredDataByProvider;
    } else {
      filteredDataByArea = filteredDataByProvider.filter((customer) =>
        area.includes(customer.area)
      );
    }

    // Apply payment status filter
    let filteredDataByPaymentStatus = [];
    if (status !== "All") {
      filteredDataByPaymentStatus = filteredDataByArea.filter(
        (customer) => customer.payment_status === status
      );
    } else {
      filteredDataByPaymentStatus = filteredDataByArea;
    }

    setFilteredData(filteredDataByPaymentStatus);
    setShowTable(true);
  };

  useEffect(() => {
    if (userData.customers) {
      setFilteredData(userData?.customers);
    }
    if (userData.msg_temp) {
      const msgtemps = userData?.msg_temp.filter(
        (item) => item.msg_category === "Message"
      );
      setMsgTemp(msgtemps);
    }
  }, [userData]);
  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      // If header checkbox is checked, select all checkboxes
      const selectedIds = filteredData.map((item) => item.caf_no);
      setSelectedCheckboxes(selectedIds);
    } else {
      // If header checkbox is unchecked, deselect all checkboxes
      setSelectedCheckboxes([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;
    if (checked) {
      // If checkbox is checked, add to selectedCheckboxes
      setSelectedCheckboxes((prevSelected) => [...prevSelected, id]);
    } else {
      // If checkbox is unchecked, remove from selectedCheckboxes
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const handleSendMessages = async () => {
    // Logic to send selected checkboxes to backend
    if (selectedCheckboxes <= 0) {
      toast.error("Select atleast 1 customer");
    } else {
      try {
        const msg = await axios.post(
          "/sendmsg",
          {
            cable_msg_temp: inputRefMessages.current.value,
            cust: selectedCheckboxes,
          },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
              lcono: localStorage.getItem("lcono"),
            },
          }
        );
        if (msg.status === 200) {
          setSelectedCheckboxes([]);
          setShowTable(false);
          setFilteredData([]);
          setRefresh(true);
          toast.success("Message Sent Successfully");
        }
      } catch (error) {
        toast.error("Error Sending Messages");
      }
    }

    // You can now send selectedCheckboxes to the backend
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        minHeight: "100vh",
        margin: { xs: "5px 10px", sm: "10px 40px" },
      }}
    >
      <Typography
        sx={{
          color: "#fbbd2c",
          textAlign: "center",
          fontSize: "20px",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        Send Messages
      </Typography>
      {/* Forms */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          marginTop: "10px",
          flexWrap: "wrap",
        }}
      >
        {/* Select ISP Provider*/}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Select Provider</InputLabel>
          <StyledSelect
            defaultValue={"All"}
            inputProps={{
              "aria-label": "Without label",
              ref: inputRefProvider,
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {userData?.mso_isp &&
              userData.mso_isp.map((prov) => (
                <MenuItem value={prov.provider_name} key={prov._id}>
                  {prov.provider_name}
                </MenuItem>
              ))}
          </StyledSelect>
        </Box>
        {/* Select Area*/}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Select Area</InputLabel>
          <StyledSelect
            defaultValue={"All"}
            inputProps={{
              "aria-label": "Without label",
              ref: inputRefArea,
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {userData?.areas &&
              userData.areas.map((area) => (
                <MenuItem value={area.area_name} key={area._id}>
                  {area.area_name}
                </MenuItem>
              ))}
          </StyledSelect>
        </Box>
        {/* Select Message Templates*/}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Select Messages</InputLabel>
          <StyledSelect
            defaultValue={"none"}
            inputProps={{
              "aria-label": "Without label",
              ref: inputRefMessages,
            }}
          >
            <MenuItem value={"none"}>None</MenuItem>
            {msgtemp &&
              msgtemp.map((item) => (
                <MenuItem value={item.msg} key={item.msg}>
                  {item.msg}
                </MenuItem>
              ))}
          </StyledSelect>
        </Box>
        {/* Select Payment Status*/}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
            margin: "10px 10px",
          }}
        >
          <InputLabel sx={{ color: "#fff" }}>Payment Status</InputLabel>
          <StyledSelect
            defaultValue={"All"}
            inputProps={{
              "aria-label": "Without label",
              ref: inputRefCustomerType,
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Paid"}>Paid</MenuItem>
            <MenuItem value={"Unpaid"}>Unpaid</MenuItem>
          </StyledSelect>
        </Box>
        <Box sx={{ marginTop: "20px", gap: "20px", display: "flex" }}>
          {" "}
          <Button
            sx={{
              color: "#000",
              backgroundColor: "#fbbd2c",
              padding: "10px",
              "&:hover": { color: "#000", backgroundColor: "#fbbd2c" },
            }}
            onClick={handleGetCustomers}
          >
            Get Customers
          </Button>
          <Button
            sx={{
              color: "#000",
              backgroundColor: "#fbbd2c",
              padding: "10px",
              "&:hover": { color: "#000", backgroundColor: "#fbbd2c" },
            }}
            onClick={handleSendMessages}
          >
            Send Messages
          </Button>
        </Box>
      </Box>
      {showTable && (
        <Box
          sx={{
            marginTop: "20px",
            overflow: "scroll",
            height: "500px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  <input
                    type="checkbox"
                    onChange={handleHeaderCheckboxChange}
                  />
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  S.No
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Name
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  CAF No
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Mobile
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  STB NO
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Balance
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Payments
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData &&
                filteredData.map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      <input
                        type="checkbox"
                        onChange={(event) =>
                          handleCheckboxChange(event, item.caf_no)
                        }
                        checked={selectedCheckboxes.includes(item.caf_no)}
                      />
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }}>
                      {item.customer_name}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.caf_no.toUpperCase()}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.mobile}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.stb_no}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.cable_balance}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.payment_status}
                    </TableCell>
                    <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                      {item.customer_status}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default Messages;
