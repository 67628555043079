import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Paper,
  InputBase,
  Button,
  Modal,
} from "@mui/material";
import { Geolocation } from "@capacitor/geolocation";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CallIcon from "@mui/icons-material/Call";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CustomerPay from "../components/modals/customerPay";
import axios from "../api/axios";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import toast from "react-hot-toast";
import NearMeIcon from "@mui/icons-material/NearMe";
import { getRoles, user_name } from "../context/RequireAuth";
import AuthContext from "../context/authContext";
import AddIcon from "@mui/icons-material/Add";
import AddCustomerModal from "../components/modals/addcustomer";
import EditIcon from "@mui/icons-material/Edit";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#fff",
      backgroundImage: "linear-gradient(to bottom right, #302b63, #24243e)",
    },
  },
};

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  height: 150,
  color: "#fff",
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
};

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 200,
});

const CustomerList = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const [selectedArea, setSelectedArea] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [reports, setReports] = useState([]);
  const [reportLength, setReportlength] = useState(0);
  const [clientArea, setClientArea] = useState([]);
  const [openPay, setOpenPay] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [custID, setCustId] = useState("");
  const [custViewData, setCustViewData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState([]);

  const handleClosePay = () => {
    setOpenPay(false);
    setRefresh(true);
  };

  const handleOpenCustomerModal = () => {
    setOpenCustomerModal(true);
  };
  const handleCloseCustomerModal = () => {
    setOpenCustomerModal(false);
    setRefresh(true);
    setEditMode(false);
    setEditData([]);
  };

  const handleAreaFilter = (area) => {
    let filteredDataByArea = areaData;

    if (area === "All") {
      filteredDataByArea = areaData;
    } else {
      filteredDataByArea = areaData.filter(
        (customer) => customer.area === area
      );
    }
    setReports(filteredDataByArea);
    setReportlength(filteredDataByArea.length);
    setSearchData(filteredDataByArea);
  };

  const handleSearch = (text) => {
    const searchfilter = searchData;

    // If the search text is empty, reset the reports to the original data
    if (!text.trim()) {
      setReports(searchfilter);
      setReportlength(searchfilter.length);
      return;
    }

    const filteredReports = searchfilter.filter((customer) => {
      // Extract specific fields to search within
      const { customer_name, mobile, stb_no, caf_no, area } = customer;

      // Check if any of the specified fields contain the search term
      return (
        (customer_name && customer_name.toLowerCase().includes(text)) ||
        (mobile && mobile.toString().toLowerCase().includes(text)) ||
        (stb_no && stb_no.toLowerCase().includes(text)) ||
        (caf_no && caf_no.toLowerCase().includes(text)) ||
        (area && area.toLowerCase().includes(text))
      );
    });

    setReports(filteredReports);
    setReportlength(filteredReports.length);
  };

  // User Data Fetch
  const updateUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  // const printCurrentPosition = async () => {
  //   const position = await Geolocation.getCurrentPosition();
  //   setUserLocation({
  //     lat: position.coords.latitude,
  //     lng: position.coords.longitude,
  //   });
  //   console.log("Current position:", position.coords);
  // };
  const handleEdit = (id) => {
    const filterEdit = areaData.filter((item) => item._id === id);
    setEditData(filterEdit);
    setOpenCustomerModal(true);
    setEditMode(true);
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setRefresh(true);
  };

  useEffect(() => {
    if (userData) {
      const data = userData;
      const customers = data?.customers?.filter(
        (cust) =>
          cust.customer_status !== "trash" && cust.customer_status !== "Erased"
      );

      const userArea = userData?.users?.filter(
        (item) => item.name === user_name()
      );
      const filtrd = customers?.filter((item) =>
        userArea[0]?.area?.includes(item.area)
      );
      if (getRoles() === "user") {
        setReports(filtrd);
        setSearchData(filtrd);
        setAreaData(filtrd);
        setReportlength(filtrd?.length || 0);
        setClientArea(userArea[0].area);
      } else {
        setReports(customers);
        setReportlength(customers?.length || 0);
        setSearchData(customers);
        setAreaData(customers);
        setClientArea(userData?.areas);
      }
    }
    // printCurrentPosition();
    if (selectedArea !== "") {
      handleAreaFilter(selectedArea);
    }
    updateUserLocation();
    const locationInterval = setInterval(updateUserLocation, 10000); // Update location every 10 seconds

    return () => clearInterval(locationInterval);
  }, [userData]);

  const handleOpenConfirmModal = (id) => {
    setOpenConfirmModal(true);
    setCustId(id);
  };

  const handleConfirmAssign = async (e) => {
    if (!userLocation) {
      toast.error("Kindly Enable Location");
    } else {
      try {
        const lat = userLocation.lat;
        const long = userLocation.lng;
        const res = await axios.post(
          "/assignlocation",
          { lat, long, custID },
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
              lcono: localStorage.getItem("lcono"),
            },
          }
        );
        if (res.status === 200) {
          handleCloseConfirmModal();
          toast.success("Customer Location Added");
          setRefresh(true);
        }
      } catch (error) {
        toast.error("Error Fetching Location");
      }
    }
  };

  const handlePayment = (id) => {
    const filters = areaData.filter((item) => item._id === id);
    setCustViewData(filters);
    setOpenPay(true);
  };

  const handleNavigation = (lat, long) => {
    if (userLocation) {
      const { lat: userLat, lng: userLng } = userLocation;
      const url = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${lat},${long}&travelmode=driving`;
      window.open(url, "_blank");
    } else {
      toast.error("User location not available");
    }
  };

  const renderCustomerRow = useCallback(
    ({ index, key, parent, style }) => {
      const item = reports[index];
      return (
        <CellMeasurer
          key={key}
          cache={cache}
          parent={parent}
          columnIndex={0}
          rowIndex={index}
        >
          <Box style={style}>
            <Box
              sx={{
                border: "2px solid #fbbd2c",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {/* customer Data */}
                <Box sx={{ padding: "10px" }}>
                  <Typography sx={{ color: "#fff" }}>
                    Name: {item.customer_name.toUpperCase()}
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    Phone: {item.mobile}
                  </Typography>

                  <Typography sx={{ color: "#fff" }}>
                    Area: {item.area}
                  </Typography>
                  <Typography sx={{ color: "#fff" }}>
                    STB: {item.stb_no}
                  </Typography>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Typography sx={{ color: "#fff" }}>
                      Last:{" "}
                      {item.payments && item.payments.length > 0
                        ? item.payments
                            ?.filter(
                              (payment) =>
                                payment.remarks === "Regular" &&
                                payment.pay_date
                            ) // Filter based on remarks and pay_date
                            ?.findLast?.((payment) => payment) // Get the last valid payment
                            ?.pay_date?.split("-")
                            .reverse()
                            .join("-") // Format the date as DD-MM-YYYY
                        : "New"}
                      | {item?.caf_no?.toUpperCase()}
                    </Typography>

                    {getRoles() !== "user" && (
                      <Typography
                        variant="button"
                        sx={{ color: "#fbbd2c" }}
                        onClick={() => handleEdit(item._id)}
                      >
                        <EditIcon />
                      </Typography>
                    )}
                  </Box>
                </Box>
                {/* Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "10px 0px",
                    alignItems: "flex-end",
                  }}
                >
                  {item.payment_status === "Paid" ? (
                    <Button>
                      <DoneIcon
                        sx={{
                          color: "green",
                          fontWeight: "bolder",
                          border: "1px solid green",
                          borderRadius: "50px",
                        }}
                      />
                    </Button>
                  ) : (
                    <Button>
                      <ClearIcon
                        sx={{
                          color: "red",
                          fontWeight: "bolder",
                          border: "1px solid red",
                          borderRadius: "50px",
                        }}
                      />
                    </Button>
                  )}

                  {item.mobile !== "" && item.mobile !== 0 ? (
                    <Button component="a" href={`tel:${item?.mobile}`}>
                      <CallIcon
                        sx={{ color: "#fbbd2c", fontWeight: "bolder" }}
                      />
                    </Button>
                  ) : (
                    ""
                  )}

                  {(item.lat === "" && item.long === "") ||
                  (!item.lat && !item.long) ? (
                    <Button onClick={() => handleOpenConfirmModal(item._id)}>
                      <MyLocationIcon
                        sx={{ color: "#fbbd2c", fontWeight: "bolder" }}
                      />
                    </Button>
                  ) : (
                    <Button
                      component="a"
                      target="_blank"
                      onClick={() => handleNavigation(item.lat, item.long)}
                    >
                      <NearMeIcon
                        sx={{ color: "#fbbd2c", fontWeight: "bolder" }}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#fbbd2c",
                  padding: "0px 10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "80px",

                    textAlign: "center",
                  }}
                >
                  <Typography>Plan</Typography>
                  <Typography sx={{ fontWeight: "bolder" }}>
                    {item.cable_plan}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "80px",

                    textAlign: "center",
                  }}
                >
                  <Typography>Bal</Typography>
                  <Typography sx={{ fontWeight: "bolder" }}>
                    {item.cable_balance}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "80px",
                    textAlign: "center",
                  }}
                >
                  <Button onClick={() => handlePayment(item._id)}>
                    <CurrencyRupeeIcon
                      sx={{
                        color: "#000",
                        fontSize: "30px",
                        fontWeight: "bolder",
                      }}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </CellMeasurer>
      );
    },
    [reports, userLocation]
  );

  return (
    <Box>
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            color: "#fbbd2c",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          Customer List
        </Typography>
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "end",
            margin: "15px 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
            }}
          >
            <FormControl>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                onChange={(e) => {
                  handleAreaFilter(e.target.value);
                  setSelectedArea(e.target.value);
                }}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
                defaultValue={"All"}
                sx={{
                  backgroundColor: "#fbbd2c",
                  width: "100px",
                  height: "50px",
                  borderColor: "#fbbd2c",
                }}
              >
                <MenuItem value={"All"}>
                  <ListItemText primary={"All"} />
                </MenuItem>
                {clientArea?.map((name) =>
                  getRoles() === "user" ? (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ) : (
                    <MenuItem key={name.area_name} value={name.area_name}>
                      <ListItemText primary={name.area_name} />
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <Paper
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fbbd2c",
                borderRadius: "30px",
                width: getRoles() === "user" ? "80%" : "50%",
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: "#000",
                }}
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                inputProps={{ "aria-label": "Search" }}
              />
            </Paper>
            {getRoles() === "client" && (
              <Button
                sx={{
                  backgroundColor: "#fbbd2c",
                  // width: "50px",
                  // height: "30px",
                  borderColor: "#fbbd2c",
                  color: "#000",
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "#fbbd2c",
                  },
                }}
                onClick={handleOpenCustomerModal}
              >
                <AddIcon size="small" />
              </Button>
            )}
          </Box>
        </Box>
        {/* Lists */}
        <Box sx={{ height: "70vh", margin: "0 10px" }}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                rowCount={reportLength}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={renderCustomerRow}
              />
            )}
          </AutoSizer>
        </Box>
      </Box>
      <CustomerPay
        openPay={openPay}
        handleClosePay={handleClosePay}
        custData={custViewData[0]}
      />
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <Box sx={modalStyles}>
          <Box>
            <Typography>Confirm Location?</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
              onClick={handleCloseConfirmModal}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#fbbd2c",
                color: "#000",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": { backgroundColor: "#fbbd2c", color: "#000" },
              }}
              onClick={handleConfirmAssign}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
      <AddCustomerModal
        openCustomerModal={openCustomerModal}
        handleCloseCustomer={handleCloseCustomerModal}
        editData={editData}
        editMode={editMode}
      />
    </Box>
  );
};

export default CustomerList;
