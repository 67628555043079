import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext";
import Grids from "../../components/modals/grids";
import StockModal from "../../components/modals/StockModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import axios from "../../api/axios";

const Stocks = () => {
  const [openStock, setOpenStock] = useState(false);
  const { userData, setRefresh } = useContext(AuthContext);
  const [stockData, setStockData] = useState([]);

  const [gridToolbar, setGridToolbar] = useState(false);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const closeOpenStock = () => {
    setRefresh(true);
    setOpenStock(false);
    setEditMode(false);
    setEditData([]);
  };
  const handleDeleteStb = async (id) => {
    try {
      const response = await axios.delete(
        `/deletestb/${id}`, // Make a DELETE request to delete STB by ID
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            lcono: localStorage.getItem("lcono"),
            role: userData.role,
          },
        }
      );

      if (response.data.status === 200) {
        setRefresh(true);
        toast.success("STB deleted successfully");
        // Optionally refresh the list of STBs or perform other UI updates
      }
    } catch (error) {
      console.error("Failed to delete STB:", error);
      toast.error("Failed to delete STB");
    }
  };
  const handleOpenEditStb = async (id) => {
    const filterEdit = stockData.filter((item) => item._id === id);
    setEditData(filterEdit[0]);
    setEditMode(true);
    setOpenStock(true);
  };
  useEffect(() => {
    console.log(userData?.stocks);
    if (userData) {
      setStockData(userData?.stocks?.filter((item) => item.status !== "Used"));
    }
  }, [userData]);

  const rows = stockData?.map((item, index) => ({
    index: index + 1,
    id: item._id,
    stb_no: item.stb_no?.toUpperCase(),
    stb_type: item.stb_type?.toUpperCase(),
    stb_provider: item.stb_provider,
    status: item.status.toUpperCase() || "N/A",
    purchase_date:
      item.purchase_date?.split("T")[0].split("-").reverse().join("-") || "N/A",
    purchase_amount: item.purchase_amount || 0,
  }));
  const columns = [
    {
      field: "index",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "stb_no",
      headerName: "STB No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "stb_type",
      headerName: "Type",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "stb_provider",
      headerName: "Provider",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "purchase_date",
      headerName: "Purchase Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "purchase_amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      flex: 1,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            sx={{
              color: "#fbbd2c",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
            }}
            onClick={() => handleOpenEditStb(params.row.id)}
          >
            <Icon>
              <EditIcon />
            </Icon>
          </IconButton>

          <IconButton
            sx={{
              color: "#fbbd2c",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
            }}
            onClick={() => handleDeleteStb(params.row.id)}
          >
            <Icon>
              <DeleteIcon />
            </Icon>
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        height: "100vh",
        marginTop: "40px",
        borderRadius: "30px",
      }}
    >
      <Box
        sx={{
          margin: "10px 10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Button
          title="Add Stock"
          sx={{
            color: "#000",
            width: { xs: "100%", sm: "max-content" },
            backgroundColor: "#fbbd2c",
            // width: { xs: "max-content", sm: "180px" },
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "10px 15px",
            "&:hover": {
              backgroundColor: "#fbbd2c",
            },
          }}
          onClick={() => setOpenStock(true)}
        >
          Add STB
        </Button>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            // width: "100%",
            backgroundColor: "#fbbd2c",
            borderRadius: "10px",
          }}
        >
          <InputBase
            sx={{
              flex: 1,
              p: 1,
              color: "#000",
            }}
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            inputProps={{ "aria-label": "Search" }}
          />
        </Paper>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  padding: 0,
                  color: "#fbbd2c", // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: "#fbbd2c", // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: "#fbbd2c", // Label color
              mt: 1,
            }}
          />
        </FormControl>
      </Box>{" "}
      <Box>
        <Grids
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={search}
        />
      </Box>
      <StockModal
        openStock={openStock}
        closeOpenStock={closeOpenStock}
        editData={editData}
        editMode={editMode}
      />
    </Box>
  );
};

export default Stocks;
