import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  InputBase,
  Modal,
  Fade,
  Backdrop,
  InputLabel,
  styled,
} from "@mui/material";
import axios from "../../api/axios";
import toast from "react-hot-toast";
import AuthContext from "../../context/authContext";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  width: "200px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary, // You can adjust the color as needed
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 900 },
  height: { md: 600, sm: 500, lg: 700 },
  backgroundImage:
    "linear-gradient(to bottom right, #0f0c29, #302b63, #24243e)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const addCustomer_URL = "/addCustomers";

const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

const typos = (label) => {
  return (
    <Typography
      sx={{
        fontSize: "18px",
        fontWeight: "bolder",
        color: "#fbbd2c",
      }}
    >
      {label}
    </Typography>
  );
};

const styledSelectBox = (inputLabel, defaultValues, inputrefs, menuItems) => {
  return (
    <Box
      sx={{
        color: "#fff",
        alignItems: "start",
        margin: "10px 10px",
      }}
    >
      <InputLabel sx={{ color: "#fff" }}>{inputLabel}</InputLabel>
      <StyledSelect
        defaultValue={defaultValues}
        inputProps={{
          "aria-label": "Without label",
          ref: inputrefs,
        }}
      >
        {menuItems?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </StyledSelect>
    </Box>
  );
};

const styledInputBox = (
  inputLabel,
  placeholders,
  types,
  inputrefs,
  defaultValues
) => {
  return (
    <Box
      sx={{
        color: "#fff",
        alignItems: "start",
        margin: "10px 10px",
      }}
    >
      <InputLabel sx={{ color: "#fff" }}>{inputLabel}</InputLabel>
      <StyledInputBase
        placeholder={placeholders}
        type={types}
        inputProps={{
          ref: inputrefs,
          defaultValue: defaultValues,
        }}
      />
    </Box>
  );
};

const AddCustomerModal = ({
  openCustomerModal,
  handleCloseCustomer,
  editData,
  editMode,
}) => {
  const { userData } = useContext(AuthContext);
  const [error, setError] = useState("");
  const inputRefregister_date = useRef();
  const inputRefcafNo = useRef("");
  const inputRefcustName = useRef("");
  const inputRefdate_of_birth = useRef();
  const inputRefarea = useRef("");
  const inputRefaddress = useRef("");
  const inputRefmobile = useRef("");
  const inputRefemail = useRef("");
  const inputRefstb_no = useRef("");
  const inputRefdeposit = useRef("");
  const inputRefinstallation_charges = useRef("");
  const inputRefCuststatus = useRef("");
  const inputRefgender = useRef("");
  const inputRefhouse_type = useRef("");
  const inputRefbuilding_type = useRef("");
  const inputRefplan = useRef("");
  const inputRefid_proof_type = useRef("");
  const inputRefid_proof_number = useRef("");
  const inputRefpincode = useRef();
  const inputRefalternate_mobile = useRef("");
  const inputRefaddress_proof = useRef("");
  const inputRefaddress_proofno = useRef("");
  const inputRefbalance = useRef("");
  const inputRefdiscount = useRef("");
  const inputRefstb_provider = useRef("");
  const inputRefstb_type = useRef("");
  const inputRefremarks = useRef("");
  const inputReflatitude = useRef("");
  const inputReflongitude = useRef("");
  const inputRefPaymentStatus = useRef("");

  const handleAddCustomer = async () => {
    const custcaf =
      userData.customers &&
      userData.customers.filter(
        (item) =>
          item.caf_no.toLowerCase() ===
          inputRefcafNo.current.value.toLowerCase()
      );
    if (custcaf.length > 1) {
      toast.error("Caf No already exist");
    } else {
      if (
        inputRefcafNo.current.value === "" ||
        inputRefcustName.current.value === "" ||
        inputRefarea.current.value === "None" ||
        inputRefmobile.current.value === "" ||
        inputRefstb_no.current.value === "" ||
        // inputRefplan.current.value === "" ||
        inputRefplan.current.value === 0
      ) {
        setError("Kindly Fill all the * fields");
      } else {
        try {
          const response = await axios.post(
            addCustomer_URL,
            {
              customer_name: inputRefcustName.current.value,
              registerdate: inputRefregister_date.current.value,
              caf_no: inputRefcafNo.current.value,
              dob: inputRefdate_of_birth.current.value,
              area: inputRefarea.current.value,
              address: inputRefaddress.current.value,
              mobile: inputRefmobile.current.value,
              email_id: inputRefemail.current.value,
              stb_no: inputRefstb_no.current.value.toLowerCase(),
              cable_deposit: inputRefdeposit.current.value,
              cable_installation: inputRefinstallation_charges.current.value,
              customer_status: inputRefCuststatus.current.value,
              gender: inputRefgender.current.value,
              house_type: inputRefhouse_type.current.value,
              building_type: inputRefbuilding_type.current.value,
              cable_plan: inputRefplan.current.value,
              proof_type: inputRefid_proof_type.current.value,
              proof_no: inputRefid_proof_number.current.value,
              pincode: inputRefpincode.current.value,
              alternate_mobile: inputRefalternate_mobile.current.value,
              address_proof_type: inputRefaddress_proof.current.value,
              address_proof_no: inputRefaddress_proofno.current.value,
              cable_balance: inputRefbalance.current.value,
              cable_discount: inputRefdiscount.current.value,
              stb_provider: inputRefstb_provider.current.value,
              stb_type: inputRefstb_type.current.value,
              remarks: inputRefremarks.current.value,
              lat: inputReflatitude.current.value,
              long: inputReflongitude.current.value,
              payment_status: inputRefPaymentStatus.current.value,
            },
            {
              headers: {
                "x-auth-token": localStorage.getItem("token"),
                lcono: localStorage.getItem("lcono"),
                role: userData.role,
              },
            }
          );

          if (response.status === 200) {
            toast.success("Customer Added");
            handleCloseCustomer();
          }
        } catch (error) {
          console.error("Error adding customer:", error);
        }
      }
    }
  };

  const handleEditCustomer = async (id) => {
    const custcaf =
      userData.customers &&
      userData.customers.filter(
        (item) =>
          item.caf_no.toLowerCase() ===
          inputRefcafNo.current.value.toLowerCase()
      );
    if (custcaf.length > 1) {
      toast.error("Caf No already exist");
    } else {
      if (
        inputRefcafNo.current.value === "" ||
        inputRefcustName.current.value === "" ||
        inputRefarea.current.value === "None" ||
        inputRefmobile.current.value === "" ||
        inputRefstb_no.current.value === "" ||
        inputRefplan.current.value === "" ||
        inputRefplan.current.value === 0
      ) {
        setError("Kindly Fill all the required fields");
      } else {
        try {
          const response = await axios.put(
            "/editcustomer",
            {
              id: id,
              customer_name: inputRefcustName.current.value,
              registerdate: inputRefregister_date.current.value,
              caf_no: inputRefcafNo.current.value,
              dob: inputRefdate_of_birth.current.value,
              area: inputRefarea.current.value,
              address: inputRefaddress.current.value,
              mobile: inputRefmobile.current.value,
              email_id: inputRefemail.current.value,
              stb_no: inputRefstb_no.current.value.toLowerCase(),
              cable_deposit: inputRefdeposit.current.value,
              cable_installation: inputRefinstallation_charges.current.value,
              customer_status: inputRefCuststatus.current.value,
              gender: inputRefgender.current.value,
              house_type: inputRefhouse_type.current.value,
              building_type: inputRefbuilding_type.current.value,
              cable_plan: inputRefplan.current.value,
              proof_type: inputRefid_proof_type.current.value,
              proof_no: inputRefid_proof_number.current.value,
              pincode: inputRefpincode.current.value,
              alternate_mobile: inputRefalternate_mobile.current.value,
              address_proof_type: inputRefaddress_proof.current.value,
              address_proof_no: inputRefaddress_proofno.current.value,
              cable_balance: inputRefbalance.current.value,
              cable_discount: inputRefdiscount.current.value,
              stb_provider: inputRefstb_provider.current.value,
              stb_type: inputRefstb_type.current.value,
              remarks: inputRefremarks.current.value,
              lat: inputReflatitude.current.value,
              long: inputReflongitude.current.value,
              payment_status: inputRefPaymentStatus.current.value,
            },
            {
              headers: {
                "x-auth-token": localStorage.getItem("token"),
                lcono: localStorage.getItem("lcono"),
                role: userData.role,
              },
            }
          );
          if (response.status === 200) {
            toast.success("Customer Updated");

            handleCloseCustomer();
          }
        } catch (error) {
          console.error("Error Updating customer:", error);
        }
      }
    }
  };
  useEffect(() => {
    setError("");
  }, [
    inputRefcafNo,
    inputRefcustName,
    inputRefarea,
    inputRefmobile,
    inputRefstb_no,
    inputRefplan,
    inputRefstb_provider,
  ]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openCustomerModal}
      onClose={handleCloseCustomer}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openCustomerModal}>
        <Box sx={style}>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              flexDirection: "column",
              minHeight: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center" }}
            >
              {editMode ? "Edit Customer" : "Add Customer"}
            </Typography>{" "}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                overflowY: "scroll",
                height: "500px",
              }}
            >
              {/* New Customer Input Form */}
              {/* Customer Details */}
              <Box>
                {typos("Customer Details")}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {/* Register Date */}
                  {styledInputBox(
                    "Register Date",
                    "Register Date",
                    "date",
                    inputRefregister_date,
                    editMode && editData[0].registerdate
                      ? editData[0].registerdate.split("T")[0]
                      : getCurrentDate()
                  )}
                  {/* Caf No */}
                  {styledInputBox(
                    "Caf No/Customer ID *",
                    "Caf No",
                    "text",
                    inputRefcafNo,
                    editMode && editData[0].caf_no ? editData[0].caf_no : ""
                  )}

                  {/* Cust Name*/}
                  {styledInputBox(
                    "Customer Name *",
                    "Customer Name",
                    "text",
                    inputRefcustName,
                    editMode && editData[0].customer_name
                      ? editData[0].customer_name
                      : ""
                  )}
                  {/* Date of Birth */}
                  {styledInputBox(
                    "Date of Birth",
                    "Date of Birth",
                    "date",
                    inputRefdate_of_birth,
                    editMode && editData[0].dob
                      ? editData[0].dob.split("T")[0]
                      : getCurrentDate()
                  )}
                  {/* Customer Status */}
                  {styledSelectBox(
                    "Customer Status",
                    editMode && editData[0].customer_status
                      ? editData[0].customer_status
                      : "Active",
                    inputRefCuststatus,
                    ["Active", "InActive"]
                  )}

                  {/* Gender */}
                  {styledSelectBox(
                    "Gender",
                    editMode && editData[0].gender
                      ? editData[0].gender
                      : "Male",
                    inputRefgender,
                    ["Male", "Female"]
                  )}
                  {/* Area */}
                  <Box
                    sx={{
                      color: "#fff",
                      alignItems: "start",
                      margin: "10px 10px",
                    }}
                  >
                    <InputLabel sx={{ color: "#fff" }}>Area *</InputLabel>
                    <StyledSelect
                      defaultValue={
                        editMode && editData[0].area ? editData[0].area : "None"
                      }
                      inputProps={{
                        "aria-label": "Without label",
                        ref: inputRefarea,
                      }}
                    >
                      <MenuItem value={"None"}>None</MenuItem>
                      {userData.areas &&
                        userData.areas.map((item) => (
                          <MenuItem value={item.area_name} key={item.area_name}>
                            {item.area_name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </Box>

                  {/* House Type */}

                  {styledSelectBox(
                    "House Type",
                    editMode && editData[0].house_type
                      ? editData[0].house_type
                      : "Owned",
                    inputRefhouse_type,
                    ["Owned", "Rented"]
                  )}
                  {/* Building Type */}
                  {styledSelectBox(
                    "Building Type",
                    editMode && editData[0].building_type
                      ? editData[0].building_type
                      : "Individual House",
                    inputRefbuilding_type,
                    ["Individual House", "Apartment", "Shop", "Commercial"]
                  )}
                  {/* Address */}
                  {styledInputBox(
                    "Address",
                    "Address",
                    "text",
                    inputRefaddress,
                    editMode && editData[0].address ? editData[0].address : ""
                  )}
                  {/* Pincode */}
                  {styledInputBox(
                    "Pincode",
                    "Pincode",
                    "number",
                    inputRefpincode,
                    editMode && editData[0].pincode ? editData[0].pincode : 0
                  )}
                  {/* Mobile */}
                  {styledInputBox(
                    "Mobile *",
                    "Mobile",
                    "number",
                    inputRefmobile,
                    editMode && editData[0].mobile ? editData[0].mobile : 0
                  )}
                  {/* Alternate Mobile Number */}
                  {styledInputBox(
                    "Alternate Mobile",
                    "Alternate Mobile",
                    "number",
                    inputRefalternate_mobile,
                    editMode && editData[0].alternate_mobile
                      ? editData[0].alternate_mobile
                      : 0
                  )}
                  {/* Email */}
                  {styledInputBox(
                    "Email ID",
                    "Email ID",
                    "email",
                    inputRefemail,
                    editMode && editData[0].email_id ? editData[0].email_id : ""
                  )}
                  {/* Latitude */}
                  {styledInputBox(
                    "Latitude",
                    "Latitude",
                    "text",
                    inputReflatitude,
                    editMode && editData[0].lat ? editData[0].lat : ""
                  )}
                  {/* Longitude */}
                  {styledInputBox(
                    "Longitude",
                    "Longitude",
                    "text",
                    inputReflongitude,
                    editMode && editData[0].long ? editData[0].long : ""
                  )}
                </Box>
              </Box>
              {/* Proof Details */}
              <Box>
                {typos("Proof Details")}
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {/* ID Proof Type */}
                  {styledSelectBox(
                    "ID Proof Type",
                    editMode && editData[0].proof_type
                      ? editData[0].proof_type
                      : "Aadhar",
                    inputRefid_proof_type,
                    ["Aadhar", "Liscence", "Voter ID"]
                  )}
                  {/* ID Proof Number */}
                  {styledInputBox(
                    "ID Proof No",
                    "ID Proof No",
                    "text",
                    inputRefid_proof_number,
                    editMode && editData[0].proof_no ? editData[0].proof_no : ""
                  )}
                  {/* Address Proof Type */}
                  {styledSelectBox(
                    "Address Proof Type",
                    editMode && editData[0].address_proof_type
                      ? editData[0].address_proof_type
                      : "Bills",
                    inputRefaddress_proof,
                    ["Bills", "Agreement"]
                  )}
                  {/* Address Proof No */}
                  {styledInputBox(
                    "Address Proof No",
                    "Address Proof No",
                    "text",
                    inputRefaddress_proofno,
                    editMode && editData[0].address_proof_no
                      ? editData[0].address_proof_no
                      : ""
                  )}
                </Box>
              </Box>
              {/* Box Details */}
              <Box>
                {typos("Box Details")}
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {/* STB No */}
                  {styledInputBox(
                    "STB No *",
                    "BOX No",
                    "text",
                    inputRefstb_no,
                    editMode && editData[0].stb_no ? editData[0].stb_no : ""
                  )}
                  {/* Box Type */}
                  {styledSelectBox(
                    "Box Type",
                    editMode && editData[0].stb_type
                      ? editData[0].stb_type
                      : "SD",
                    inputRefstb_type,
                    ["SD", "HD", "Hybrid"]
                  )}
                  {/* STB Provider */}
                  {/* <Box
                    sx={{
                      color: "#fff",
                      alignItems: "start",
                      margin: "10px 10px",
                    }}
                  >
                    <InputLabel sx={{ color: "#fff" }}>Provider</InputLabel>
                    <StyledSelect
                      defaultValue={
                        editMode
                          ? editData[0].stb_provider
                          : userData.mso_isp[0].provider_name
                      }
                      inputProps={{
                        "aria-label": "Without label",
                        ref: inputRefstb_provider,
                      }}
                    >
                      <MenuItem value={"none"}>None</MenuItem>
                      {userData.mso_isp &&
                        userData.mso_isp.map((item) => (
                          <MenuItem
                            value={item.provider_name}
                            key={item.provider_name}
                          >
                            {item.provider_name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </Box> */}
                  {styledSelectBox(
                    "STB Provider",
                    editMode && editData?.[0]?.stb_provider
                      ? editData[0].stb_provider
                      : userData?.mso_isp?.[0]?.provider_name || "",
                    inputRefstb_provider, // Fixing the reference to the correct inputRef
                    userData?.mso_isp
                      ? userData.mso_isp.map((item) => item.provider_name)
                      : []
                  )}

                  {/* Plan */}
                  {styledInputBox(
                    "Plan *",
                    "Plan",
                    "number",
                    inputRefplan,
                    editMode && editData[0].cable_plan
                      ? editData[0].cable_plan
                      : 0
                  )}
                  {/* Deposit */}
                  {styledInputBox(
                    "Deposit",
                    "Deposit",
                    "number",
                    inputRefdeposit,
                    editMode && editData[0].cable_deposit
                      ? editData[0].cable_deposit
                      : 0
                  )}

                  {/* Installation */}
                  {styledInputBox(
                    "Installation",
                    "Installation",
                    "number",
                    inputRefinstallation_charges,
                    editMode && editData[0].cable_installation
                      ? editData[0].cable_installation
                      : 0
                  )}
                  {/* Balance */}
                  {styledInputBox(
                    "Balance",
                    "Balance",
                    "number",
                    inputRefbalance,
                    editMode && editData[0].cable_balance
                      ? editData[0].cable_balance
                      : 0
                  )}
                  {/* Balance */}

                  {styledInputBox(
                    "Discount",
                    "Discount",
                    "text",
                    inputRefdiscount,
                    editMode && editData[0].cable_discount
                      ? editData[0].cable_discount
                      : 0
                  )}
                </Box>
              </Box>
              {/* Remarks */}
              <Box>
                {typos("Remarks")}
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {/* Remarks*/}
                    {styledInputBox(
                      "Remarks",
                      "Remarks",
                      "text",
                      inputRefremarks,
                      editMode && editData[0].remarks ? editData[0].remarks : ""
                    )}
                  </Box>
                  <Box>
                    {styledSelectBox(
                      "Payment Status",
                      editMode && editData[0].payment_status
                        ? editData[0].payment_status
                        : "Unpaid",
                      inputRefPaymentStatus,
                      ["Paid", "Unpaid"]
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            {error && (
              <Typography style={{ color: "red", textAlign: "center" }}>
                {error}
              </Typography>
            )}
            {editMode ? (
              <Button
                sx={{
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                  },
                }}
                onClick={() => handleEditCustomer(editData && editData[0]._id)}
              >
                Update
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: "#fbbd2c",
                  color: "#000",
                  borderRadius: "20px",
                  "&:hover": {
                    backgroundColor: "#fbbd2c",
                    color: "#000",
                  },
                }}
                onClick={handleAddCustomer}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddCustomerModal;
