import React, { useContext, useMemo, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
// import ThemeContext from "../../context/themeContext";

const Grids = ({
  columns,
  rows = [],
  gridToolbar,
  searchQuery = "",
  sxstyles,
  columnVisibilityModel,
  setColumnVisibilityModel,
}) => {
  //   const { theme } = useContext(ThemeContext);

  // Memoize the filtered rows for performance optimization
  const filteredRows = useMemo(() => {
    return rows.filter((row) => {
      return Object.values(row).some((value) => {
        return value
          ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          : false;
      });
    });
  }, [rows, searchQuery]);

  return (
    <Box
      sx={{
        ...sxstyles,
        minHeight: { sm: "75vh", xs: "30vh" },
        "& .super-app-theme--header": {
          backgroundColor: "#FBBC2C",
        },

        margin: "20px 5px",
        textTransform: "capitalize",
        overflow: "auto",
      }}
    >
      {filteredRows.length > 0 ? (
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          rows={filteredRows}
          columns={columns}
          pageSize={10}
          pageSizeOptions={[10, 50, 100]}
          slots={{
            toolbar: gridToolbar ? GridToolbar : null,
          }}
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "#FBBC2C",
            },
            "& .MuiDataGrid-cell": {
              color: "#fff",
              textAlign: "center",
              whiteSpace: "nowrap",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#FBBC2C",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "#000",
              fontWeight: "bold",
              textAlign: "center",
              whiteSpace: "nowrap",
            },
            // "& .MuiDataGrid-row": {
            //   "&:nth-of-type(even)": {
            //     backgroundColor: "lightred",
            //   },
            //   "&:nth-of-type(odd)": {
            //     backgroundColor: "lightgreen",
            //   },
            // },
            "& .MuiDataGrid-footerContainer": {
              color: "#FBBC2C",
              "& .MuiTablePagination-root": {
                color: "#FBBC2C",
              },
            },
          }}
          autoHeight
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
        />
      ) : (
        <Typography
          variant="h6"
          sx={{
            color: "red",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          No results found
        </Typography>
      )}
    </Box>
  );
};

export default Grids;
