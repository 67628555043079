import {
  Box,
  Typography,
  Select,
  styled,
  InputBase,
  InputLabel,
  MenuItem,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableFooter,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import AuthContext from "../context/authContext";

const getCurrentDate = () => {
  return new Date().toISOString().split("T")[0];
};

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "115px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  fontSize: "12px",
  "&::placeholder": {
    color: theme.palette.text.secondary,
  },
  "&:focus": {
    outline: "none",
  },
}));

const DateInput = ({ defaultValue, inputRef }) => {
  const handleClick = () => {
    inputRef.current.focus();
    inputRef.current.showPicker(); // Show the date picker
  };

  return (
    <StyledInputBase
      defaultValue={defaultValue}
      placeholder="Register Date"
      type="date"
      inputProps={{ ref: inputRef }}
      onClick={handleClick}
      onFocus={handleClick} // Ensure the date picker shows on focus
    />
  );
};

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "125px",
  height: "35px",
  backgroundColor: "#fff",
  margin: "10px 0px",
  padding: "0px 15px",
  borderRadius: "20px",
  "&::placeholder": {
    marginLeft: "10px",
    color: theme.palette.text.secondary,
  },
}));

const QuickReport = () => {
  const { userData } = useContext(AuthContext);
  const inputRefFromDate = useRef(null);
  const inputRefToDate = useRef(null);
  const inputRefUser = useRef(null);
  const [showTable, setShowTable] = useState(false);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const role = localStorage.getItem("role");

  const handleSearch = () => {
    const fromDate = new Date(inputRefFromDate.current.value);
    let toDate = new Date(); // Default toDate to today if role is "user"

    let user = "";

    if (role === "client") {
      user = inputRefUser.current.value;
    } else {
      user = localStorage.getItem("user_name");
    }

    if (role === "client") {
      // If role is client, get toDate from input
      toDate = new Date(inputRefToDate.current.value);
    }

    const payments = userData.customers.flatMap((customer) =>
      customer.payments.map((payment) => ({
        ...payment,
        customerName: customer.customer_name,
        area: customer.area,
        mobile: customer.mobile,
        caf_no: customer.caf_no,
      }))
    );

    const filteredPayments = payments.filter((payment) => {
      const paymentDate = new Date(payment.pay_date);

      // Role-specific filtering logic
      if (role === "client") {
        return (
          paymentDate >= fromDate &&
          paymentDate <= toDate &&
          (user === "All" || payment.collected_by === user)
        );
      } else {
        return (
          paymentDate.toDateString() === fromDate.toDateString() &&
          payment.collected_by === user
        );
      }
    });

    setFilteredPayments(filteredPayments);
    setShowTable(true);
  };

  const totalAmount = filteredPayments.reduce(
    (total, payment) => total + payment.amount,
    0
  );

  // Function to check for duplicate payments
  const checkDuplicate = (payment, index, payments) => {
    return payments.some(
      (p, i) =>
        i !== index &&
        p.pay_date === payment.pay_date &&
        p.caf_no === payment.caf_no
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        minHeight: "100vh",
        margin: { xs: "5px 5px", sm: "10px 40px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        {/* From Date */}
        <Box
          sx={{
            color: "#fff",
            alignItems: "start",
          }}
        >
          <DateInput
            defaultValue={getCurrentDate()}
            inputRef={inputRefFromDate}
          />
        </Box>

        {/* Conditionally show the "To Date" picker for client only */}
        {role === "client" && (
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
            }}
          >
            <DateInput
              defaultValue={getCurrentDate()}
              inputRef={inputRefToDate}
            />
          </Box>
        )}

        {role === "client" && (
          <Box
            sx={{
              color: "#fff",
              alignItems: "start",
            }}
          >
            <StyledSelect
              displayEmpty
              defaultValue="All"
              inputProps={{
                "aria-label": "Without label",
                ref: inputRefUser,
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={userData?.name}> {userData?.name}</MenuItem>
              {userData?.users?.map((item) => (
                <MenuItem key={item._id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>
        )}
        {role === "client" ? (
          <Button
            onClick={handleSearch}
            sx={{
              width: "100%",
              color: "#000",
              backgroundColor: "#fbbd2c",
              "&:hover": {
                color: "#000",
                backgroundColor: "#fbbd2c",
              },
            }}
          >
            Submit
          </Button>
        ) : (
          <Button
            onClick={handleSearch}
            sx={{
              width: "50%",
              color: "#000",
              height: "40px",
              marginTop: "10px",
              backgroundColor: "#fbbd2c",
              "&:hover": {
                color: "#000",
                backgroundColor: "#fbbd2c",
              },
            }}
          >
            Submit
          </Button>
        )}
      </Box>

      {showTable && (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    width: "40%",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    width: "30%",
                  }}
                >
                  Amount
                </TableCell>
                <TableCell
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    width: "30%",
                  }}
                >
                  Area/Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPayments.map((payment, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: checkDuplicate(
                      payment,
                      index,
                      filteredPayments
                    )
                      ? "red"
                      : "inherit",
                  }}
                >
                  <TableCell
                    sx={{ color: "#fff", width: "40%", padding: "2px" }}
                  >
                    {payment.customerName} / <br /> {payment.mobile}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      width: "30%",
                      padding: "2px",
                      backgroundColor:
                        payment.amount === payment.bill_amount
                          ? "inherit"
                          : "red",
                    }}
                  >
                    {payment.amount}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#fff",
                      textAlign: "center",
                      width: "30%",
                      padding: "2px",
                    }}
                  >
                    {payment.caf_no} / <br />{" "}
                    {new Date(payment.pay_date).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ backgroundColor: "#fbbd2c" }}>
                <TableCell
                  sx={{
                    color: "#000",
                    fontSize: "20px",
                    padding: "2px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography>Total:</Typography>
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    padding: "2px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    {" "}
                    {totalAmount}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "2px",
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default QuickReport;
