import React, { useEffect, useState } from "react";
import "./styles.css";
import { Box, Typography, Grow, List, ListItemButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation, Link } from "react-router-dom";
import { getRoles } from "../../context/RequireAuth";

const SideBar = () => {
  const location = useLocation();
  const locate = location.pathname;
  const [role, setRole] = useState("");

  const [openCustomers, setOpenCustomers] = useState(
    locate === "/dashbaord/customers/cable" ||
      locate === "/dashbaord/customers/internet"
      ? true
      : false
  );

  const [openExpenses, setOpenExpenses] = useState(
    locate === "dashboard/expenses/add" || locate === "dashboard/expenses/view"
      ? true
      : false
  );
  const [openReports, setOpenReports] = useState(
    locate === "dashboard/reports/collections" ||
      locate === "dashboard/reports/customers" ||
      locate === "dashboard/reports/history"
      ? true
      : false
  );
  const [openTrash, setOpenTrash] = useState(
    locate === "dashboard/trash/customers" ||
      locate === "dashboard/trash/payments"
      ? true
      : false
  );

  const handleClickCustomers = () => {
    setOpenCustomers(!openCustomers);
  };

  const handleClickExpenses = () => {
    setOpenExpenses(!openExpenses);
  };
  const handleClickReports = () => {
    setOpenReports(!openReports);
  };
  const handleClickTrash = () => {
    setOpenTrash(!openTrash);
  };

  useEffect(() => {}, [locate]);
  useEffect(() => {
    setRole(getRoles());
  }, []);

  return (
    <Box
      sx={{
        minWidth: "12%",
        backgroundImage: "linear-gradient(to bottom, #0f0c29,#302b63)",
        minHeight: "100vh",
        display: { xs: "none", sm: "none", md: "none", lg: "flex" },
        flexDirection: "column",
      }}
    >
      <Link
        to="/dashboard"
        underline="none"
        onClick={() => {
          setOpenCustomers(false);
          setOpenExpenses(false);

          setOpenReports(false);
          setOpenTrash(false);
        }}
      >
        <Box
          sx={{
            color: "#fff",
            fontSize: "40px",
            width: "90%",
            alignItems: "center",
            height: "80px",
            justifyContent: "center",
            display: "flex",
            fontFamily: "Rasa, serif",
          }}
        >
          MapsApp
        </Box>
      </Link>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <List
          sx={{
            width: "80%",
            color: "#fff",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
          component="nav"
        >
          <Link
            to="/dashboard"
            underline="none"
            sx={{ color: "#fff" }}
            onClick={() => {
              setOpenCustomers(false);
              setOpenExpenses(false);
              setOpenReports(false);
              setOpenTrash(false);
            }}
          >
            <ListItemButton
              sx={{
                borderRadius: "10px",
              }}
            >
              {/* <ListItemText primary="Dashboard" /> */}
              <Typography
                sx={{ color: locate === "/dashboard" ? "#fdbb2d" : "#fff" }}
              >
                Dashboard
              </Typography>
            </ListItemButton>
          </Link>
          <Link
            to="customers"
            underline="none"
            checked={openCustomers}
            onClick={() => {
              handleClickCustomers();
              setOpenExpenses(false);
              setOpenReports(false);
              setOpenTrash(false);
            }}
            sx={{
              borderRadius: "10px",
            }}
          >
            {/* <ListItemText primary="Customers" /> */}
            <ListItemButton
              sx={{
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  color: locate === "/dashboard/customers" ? "#fdbb2d" : "#fff",
                }}
              >
                Customers
              </Typography>
            </ListItemButton>
          </Link>

          {role === "client" && (
            <Link
              to="payments"
              underline="none"
              sx={{ color: "#fff" }}
              onClick={() => {
                setOpenCustomers(false);
                setOpenExpenses(false);

                setOpenReports(false);
                setOpenTrash(false);
              }}
            >
              <ListItemButton
                sx={{
                  borderRadius: "10px",
                }}
              >
                {/* <ListItemText primary="Dashboard" /> */}
                <Typography
                  sx={{
                    color:
                      locate === "/dashboard/payments" ? "#fdbb2d" : "#fff",
                  }}
                >
                  Payments
                </Typography>
              </ListItemButton>
            </Link>
          )}

          {role === "client" && (
            <Link
              to="complaints"
              underline="none"
              sx={{ color: "#fff" }}
              onClick={() => {
                setOpenCustomers(false);
                setOpenExpenses(false);

                setOpenReports(false);
                setOpenTrash(false);
              }}
            >
              <ListItemButton
                sx={{
                  borderRadius: "10px",
                }}
              >
                {/* <ListItemText primary="Dashboard" /> */}
                <Typography
                  sx={{
                    color:
                      locate === "/dashboard/complaints" ? "#fdbb2d" : "#fff",
                  }}
                >
                  Complaints
                </Typography>
              </ListItemButton>
            </Link>
          )}

          {role === "client" && (
            <>
              <ListItemButton
                checked={openTrash}
                onClick={() => {
                  setOpenCustomers(false);
                  handleClickTrash();
                  setOpenReports(false);
                  setOpenExpenses(false);
                }}
                sx={{
                  borderRadius: "10px",
                }}
              >
                {/* <ListItemText primary="Customers" /> */}
                <Typography
                  sx={{
                    color:
                      locate === "/dashboard/trash/customers" ||
                      locate === "/dashboard/trash/payments"
                        ? "#fdbb2d"
                        : "#fff",
                  }}
                >
                  Trash
                </Typography>

                {openTrash ? (
                  <ExpandLess
                    sx={{
                      position: "absolute",
                      right: "5px",
                      color:
                        locate === "/dashboard/trash/customers" ||
                        locate === "/dashboard/trash/payments"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  />
                ) : (
                  <ExpandMore
                    sx={{
                      position: "absolute",
                      right: "5px",
                      color:
                        locate === "/dashboard/trash/customers" ||
                        locate === "/dashboard/trash/payments"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  />
                )}
              </ListItemButton>

              <Collapse in={openTrash} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Grow
                    in={openTrash}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openTrash ? { timeout: 1000 } : {})}
                  >
                    <Link
                      to="trash/customers"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#FFB946" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/trash/customers"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          Customers
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>

                  <Grow
                    in={openTrash}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openTrash ? { timeout: 1300 } : {})}
                  >
                    <Link
                      to="trash/payments"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#F7685B" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/trash/payments"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          Payments
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>
                </List>
              </Collapse>
              <ListItemButton
                onClick={() => {
                  setOpenCustomers(false);
                  handleClickReports();
                  setOpenExpenses(false);
                  setOpenTrash(false);
                }}
                sx={{
                  borderRadius: "10px",
                }}
              >
                {/* <ListItemText primary="Customers" /> */}
                <Typography
                  sx={{
                    color:
                      locate === "/dashboard/reports/customers" ||
                      locate === "/dashboard/reports/collections" ||
                      locate === "/dashboard/reports/history" ||
                      locate === "/dashboard/reports/messages"
                        ? "#fdbb2d"
                        : "#fff",
                  }}
                >
                  Reports
                </Typography>

                {openReports ? (
                  <ExpandLess
                    sx={{
                      position: "absolute",
                      right: "5px",
                      color:
                        locate === "/dashboard/reports/customers" ||
                        locate === "/dashboard/reports/collections" ||
                        locate === "/dashboard/reports/history" ||
                        locate === "/dashboard/reports/messages"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  />
                ) : (
                  <ExpandMore
                    sx={{
                      position: "absolute",
                      right: "5px",
                      color:
                        locate === "/dashboard/reports/customers" ||
                        locate === "/dashboard/reports/collections" ||
                        locate === "/dashboard/reports/history" ||
                        locate === "/dashboard/reports/messages"
                          ? "#fdbb2d"
                          : "#fff",
                    }}
                  />
                )}
              </ListItemButton>

              <Collapse in={openReports} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Grow
                    in={openReports}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openReports ? { timeout: 1000 } : {})}
                  >
                    <Link
                      to="reports/collections"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#FFB946" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/reports/collections"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          Collections
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>
                  <Grow
                    in={openReports}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openReports ? { timeout: 1300 } : {})}
                  >
                    <Link
                      to="reports/customers"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#F7685B" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/reports/customers"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          Customers
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>
                  {/* Stocks */}
                  <Grow
                    in={openReports}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openReports ? { timeout: 1300 } : {})}
                  >
                    <Link
                      to="reports/stocks"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#2ED47A" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/reports/stocks"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          Stocks
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>
                  {/* History */}
                  <Grow
                    in={openReports}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openReports ? { timeout: 1600 } : {})}
                  >
                    <Link
                      to="reports/history"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#F78D1E" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/reports/history"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          History
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>
                  <Grow
                    in={openReports}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openReports ? { timeout: 1600 } : {})}
                  >
                    <Link
                      to="reports/messages"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#FFB946" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/reports/messages"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          Messages
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>
                  <Grow
                    in={openReports}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(openReports ? { timeout: 1600 } : {})}
                  >
                    <Link
                      to="reports/logs"
                      underline="none"
                      sx={{ color: "#fff" }}
                    >
                      <ListItemButton>
                        <div
                          className="listicon_color"
                          style={{ borderColor: "#F7685B" }}
                        ></div>
                        <Typography
                          sx={{
                            color:
                              locate === "/dashboard/reports/logs"
                                ? "#fdbb2d"
                                : "#fff",
                          }}
                        >
                          Logs
                        </Typography>
                      </ListItemButton>
                    </Link>
                  </Grow>
                </List>
              </Collapse>
            </>
          )}

          <Link
            to="maps"
            underline="none"
            sx={{ color: "#fff" }}
            onClick={() => {
              setOpenCustomers(false);
              setOpenExpenses(false);

              setOpenReports(false);
              setOpenTrash(false);
            }}
          >
            <ListItemButton
              sx={{
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <ListItemText primary="Dashboard" /> */}
              <Typography
                sx={{
                  color: locate === "/dashboard/maps" ? "#fdbb2d" : "#fff",
                }}
              >
                Maps
              </Typography>
            </ListItemButton>
          </Link>
          {role === "client" && (
            <Link to="/dashboard/messages">
              <ListItemButton
                onClick={() => {
                  setOpenCustomers(false);
                  setOpenReports(false);
                  setOpenExpenses(false);
                  setOpenTrash(false);
                }}
                sx={{
                  borderRadius: "10px",
                }}
              >
                {/* <ListItemText primary="Customers" /> */}
                <Typography
                  sx={{
                    color:
                      locate === "/dashboard/messages" ? "#fdbb2d" : "#fff",
                  }}
                >
                  Messages
                </Typography>
              </ListItemButton>
            </Link>
          )}
        </List>
      </Box>
    </Box>
  );
};

export default SideBar;

//    <ListItemButton
//      checked={openExpenses}
//      onClick={() => {
//        setOpenCustomers(false);
//        handleClickExpenses();
//        setOpenReports(false);
//        setOpenTrash(false);
//      }}
//      sx={{
//        borderRadius: "10px",
//      }}
//    >
//
//      <Typography
//        sx={{
//          color:
//            locate === "/dashboard/expenses/purchase" ||
//            locate === "/dashboard/expenses/office"
//              ? "#fdbb2d"
//              : "#fff",
//        }}
//      >
//        Expenses
//      </Typography>
//      {openExpenses ? (
//        <ExpandLess
//          sx={{
//            position: "absolute",
//            right: "5px",
//            color:
//              locate === "/dashboard/expenses/purchase" ||
//              locate === "/dashboard/expenses/office"
//                ? "#fdbb2d"
//                : "#fff",
//          }}
//        />
//      ) : (
//        <ExpandMore
//          sx={{
//            position: "absolute",
//            right: "5px",
//            color:
//              locate === "/dashboard/expenses/purchase" ||
//              locate === "/dashboard/expenses/office"
//                ? "#fdbb2d"
//                : "#fff",
//          }}
//        />
//      )}
//    </ListItemButton>
//    <Collapse in={openExpenses} timeout="auto" unmountOnExit>
//      <List component="div" disablePadding>
//        <Grow
//          in={openExpenses}
//          style={{ transformOrigin: "0 0 0" }}
//          {...(openExpenses ? { timeout: 1000 } : {})}
//        >
//          <Link
//            to="expenses/purchase"
//            underline="none"
//            sx={{ color: "#fff" }}
//          >
//            <ListItemButton>
//              <div
//                className="listicon_color"
//                style={{ borderColor: "#FFB946" }}
//              ></div>
//              <Typography
//                sx={{
//                  color:
//                    locate === "/dashboard/expenses/purchase"
//                      ? "#fdbb2d"
//                      : "#fff",
//                }}
//              >
//                Purchase
//              </Typography>
//            </ListItemButton>
//          </Link>
//        </Grow>
//        <Grow
//          in={openExpenses}
//          style={{ transformOrigin: "0 0 0" }}
//          {...(openExpenses ? { timeout: 1300 } : {})}
//        >
//          <Link
//            to="expenses/office"
//            underline="none"
//            sx={{ color: "#fff" }}
//          >
//            <ListItemButton>
//              <div
//                className="listicon_color"
//                style={{ borderColor: "#F7685B" }}
//              ></div>
//              <Typography
//                sx={{
//                  color:
//                    locate === "/dashboard/expenses/office"
//                      ? "#fdbb2d"
//                      : "#fff",
//                }}
//              >
//                Office
//              </Typography>
//            </ListItemButton>
//          </Link>
//        </Grow>
//      </List>
//    </Collapse>
//    <Link
//      to="daybook"
//      underline="none"
//      sx={{ color: "#fff" }}
//      onClick={() => {
//        setOpenCustomers(false);
//        setOpenExpenses(false);
//        setOpenReports(false);
//        setOpenTrash(false);
//      }}
//    >
//      <ListItemButton
//        sx={{
//          borderRadius: "10px",
//        }}
//      >
//        {/* <ListItemText primary="Dashboard" /> */}
//        <Typography
//          sx={{
//            color:
//              locate === "/dashboard/daybook" ? "#fdbb2d" : "#fff",
//          }}
//        >
//          DayBook
//        </Typography>
//      </ListItemButton>
//    </Link>
