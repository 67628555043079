import {
  Box,
  Button,
  Table,
  Typography,
  TextField,
  Stack,
  Autocomplete,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useState, useContext } from "react";
import toast from "react-hot-toast";
import AuthContext from "../../context/authContext";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../api/axios";
// import date from "date-and-time";

// Define a date to get the offset
// const now = new Date();

// Get the time zone offset for IST (UTC+05:30)
// const timeZoneOffset =date.subtract(now, date.timezone(now, "+0530")).toMilliseconds() / 60000;

// console.log(
//   "Time Zone Offset for UTC+05:30 (IST):",
//   date.format(now, "DD/MM/YYYY hh:mm A")
// );

const TrashPayments = () => {
  const { userData, setRefresh } = useContext(AuthContext);
  const [customerData, setCustomerData] = useState([]);
  const [searchCafNo, setSearchCafNo] = useState("");

  const handleDeletePayment = async (id, itemid) => {
    try {
      const res = await axios.delete(`deletepayment/${itemid}/${id}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          lcono: localStorage.getItem("lcono"),
        },
      });
      if (res.status === 200) {
        setRefresh(true);
        toast.success("Payment Deleted Successfully");
        setSearchCafNo("");
        setCustomerData([]);
      }
    } catch (error) {
      toast.error("Error Deleting Payment");
    }
  };
  const handleSearchCafNo = (e, selectedValue) => {
    if (selectedValue !== "" || selectedValue !== null) {
      e.preventDefault();

      if (!selectedValue) {
        toast.error("Kindly fill CafNo");
        return;
      }

      let searchcaf = selectedValue.toLowerCase();
      let searchcaf1 = searchcaf.split(" ")[0];

      // Get current year and month in "YYYY-MM" format
      let current = new Date().toISOString().slice(0, 7);
      console.log(current);

      // Filter customers by CafNo
      const filteredUsers = userData.customers.filter(
        (cust) => cust.caf_no.toLowerCase() === searchcaf1
      );

      if (filteredUsers.length > 0) {
        // Filter payments for the current month
        const currentMonthPayments = filteredUsers[0].payments.filter(
          (payment) => payment.pay_date?.slice(0, 7) === current
        );

        if (currentMonthPayments.length > 0) {
          currentMonthPayments.reverse();
          setCustomerData([
            { ...filteredUsers[0], payments: currentMonthPayments },
          ]);
        } else {
          setCustomerData([]);
          toast.error("No Payments Found for the Current Month");
        }
      } else {
        setCustomerData([]);
        toast.error("No Customers Found");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        height: "100vh",
        marginTop: "40px",
        borderRadius: "30px",
      }}
    >
      <Typography sx={{ color: "#fbbd2c", textAlign: "center" }}>
        Payment Trash
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: "10px",
          gap: "20px",
        }}
      >
        <Stack spacing={2} sx={{ width: 300 }}>
          <Autocomplete
            sx={{
              display: "flex",
              alignItems: "center",
              width: 300,
              backgroundColor: "#fbbd2c",
              borderRadius: "30px",
            }}
            defaultValue={null}
            disableClearable
            onChange={(e, value) => {
              setSearchCafNo(value);
              handleSearchCafNo(e, value);
            }}
            value={searchCafNo}
            freeSolo
            options={
              (userData &&
                userData.customers &&
                userData.customers
                  .filter((item) => item.customer_status === "Active")
                  .map(
                    (option) =>
                      option.caf_no.toUpperCase() +
                      " " +
                      option.mobile +
                      " " +
                      option.customer_name
                  )) ||
              []
            }
            filterOptions={(options, { inputValue }) =>
              options
                .filter((option) =>
                  option.toLowerCase().includes(inputValue.toLowerCase())
                )
                .slice(0, 7)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </Stack>
      </Box>

      {customerData &&
        customerData.map((item, index) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              margin: { xs: "0px", md: "60px 30px" },
              gap: { xs: "10px", md: "50px" },
              justifyContent: "center",
              overflow: "auto",
            }}
            key={item._id}
          >
            <Box
              sx={{
                color: "#fff",
                backgroundColor: "#24243e",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: { xs: "0px", sm: "20px" },
                border: "2px solid #fbbd2c",
                borderRadius: "20px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                    }}
                  >
                    {item.customer_name.toUpperCase()}
                  </Typography>
                  <Typography sx={{ fontWeight: "bolder", fontSize: "20px" }}>
                    {item.caf_no.toUpperCase()}
                  </Typography>

                  {item.payment_status === "Paid" ? (
                    <Typography sx={{ color: "#30D479", fontSize: "20px" }}>
                      Paid
                    </Typography>
                  ) : (
                    <Typography sx={{ color: "red", fontSize: "20px" }}>
                      Unpaid
                    </Typography>
                  )}
                </Box>
                <hr />
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          S.No
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            display: { xs: "none", sm: "flex" },
                          }}
                        >
                          STB No
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                          }}
                        >
                          Pay Date
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            display: { xs: "none", sm: "flex" },
                          }}
                        >
                          Pay Type
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            display: { xs: "none", sm: "flex" },
                          }}
                        >
                          Collected By
                        </TableCell>
                        <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        overflow: "auto",
                      }}
                    >
                      {item.payments &&
                        item.payments
                          .filter((pay) => pay.remarks !== "Regular Unpaid")
                          .map((payment, index) => (
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  borderBottom: "none",
                                }}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#fff",
                                  display: {
                                    xs: "none",
                                    sm: "flex",
                                    borderBottom: "none",
                                  },
                                }}
                              >
                                {payment.device_no}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  borderBottom: "none",
                                }}
                              >
                                {payment.pay_date}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  display: { xs: "none", sm: "flex" },
                                  borderBottom: "none",
                                }}
                              >
                                {payment.pay_type}
                              </TableCell>

                              <TableCell
                                sx={{
                                  color: "#fff",
                                  textAlign: "center",
                                  borderBottom: "none",
                                }}
                              >
                                {payment.amount}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#fff",
                                  display: { xs: "none", sm: "flex" },
                                  borderBottom: "none",
                                }}
                              >
                                {payment.collected_by}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  textAlign: "center",
                                  padding: 0,
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    handleDeletePayment(payment._id, item._id)
                                  }
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "#fff",
                                      textAlign: "center",
                                      borderBottom: "none",
                                    }}
                                  />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>

                  {/* <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ width: "120px" }}>Name</Typography>:
                    <Typography>{item.payments[0].device_no}</Typography>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default TrashPayments;
